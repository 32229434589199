#order_resume-card-container {
  height: 75px;
  padding: 10px;
  background: rgba(204, 186, 186, 0.08);
}

.order_resume-container-title-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.order_resume-card-order-price-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.order_resume-card-order {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.order_resume-card-order:hover {
  color: rgba(0, 0, 0, 0.87);
}

.order_resume-card-order:active {
  color: rgba(0, 0, 0, 0.87);
}

.order_resume-card-task {
  font-size: 13px;
  color: #000;
  overflow-x: auto;
  overflow-y: hidden;
}

.order_resume-card-price {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.order_resume-body-concept-item {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
}

.order_resume-card-creator {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.order_resume-card-created_at {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.order_resume-flag-payment-state {
  margin-top: 5px;
  min-width: 5px;
  height: 30px;
  position: fixed;
}

.order_resume-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.order_resume-counter {
  font-size: 12px;
  font-family: Raleway;
  color: rgba(0, 0, 0, 0.87);
}
