#payment_line-card-container {
  padding: 10px;
  background: rgba(204, 186, 186, 0.08);
  height: 115px;
}

.payment_line-flag-payment-state {
  margin-top: 5px;
  min-width: 5px;
  height: 30px;
  position: fixed;
}

.payment_line-body {
  display: flex;
  flex-direction: column;
}

.payment_line-title {
  display: flex;
  justify-content: space-between;
}

.payment_line-title-line-creator-container {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.payment_line-title-creator {
  font-size: 12px;
  color: silver;
}

.payment_line-title-line {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

.payment_line-title-line:active {
  color: lightgrey;
}

.payment_line-price-action-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.payment_line-price {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.payment_line-action {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.payment_line-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.payment_line-concept-item {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
}

.payment_line-concept-value {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.payment_line-order {
  font-size: 12px;
  color: lightgrey;
}

.payment_line-order:active {
  color: white;
}
