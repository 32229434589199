.sign_payment-container {
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: #3f3f49;
}

.sign_payment-container::-webkit-scrollbar{
  display: none;
}

.sign_payment-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  height: 70vh;
}

.sign_payment-button-sign-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.sign_payment-line-container{
  width: 95%;
  display: flex;
  justify-content: space-between;
}

.sign_payment-line-concept{
  font-family: Raleway;
  color: lightblue;
}

.sign_payment-line-value{
  font-family: Raleway;
  color: lightgray;
}

.sign_payment-head{
  width: 90%;
  height: 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sign_payment-signatures-item-container{
  display: flex;
  align-items: center;
}

.sign_payment-signatures-container{
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  background-color: #2c2c36;
  display: flex;
  flex-wrap: wrap;
}

.sign_payment-signatures-user_id-created_at-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin: 0 10px 10px 0;
}

.sign_payment-signatures-user_id{
  margin: 0 10px;
}

.sign_payment-signatures-created_at{
  margin: 0 10px;
  font-size: 12px;
  color: cadetblue; 
  font-family: Raleway;
}

/* Popover */

.sign_payment-popover-list{
  /* width: 100px; */
  list-style: none;
  padding: 7px;
  color: white;
  background-color: #2c2c36;
  margin: 0;
}

.sign_payment-popover-item-disabled{
  color: gray;
  padding: 5px;
}

.sign_payment-popover-item{
  font-family: Raleway;
  height: 30px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  color: white;
}

.sign_payment-popover-item:hover{
  color: lightseagreen;
}

.sign_payment-popover-item:active{
  color: lightskyblue;
}

.sign_payment-popover-item-selected{
  background-color: #3f3f49;
}